export default {
  path: "/listing",
  meta: { auth: true, public: false },
  title: "Listing",
  name: "Listing",
  component: () =>
    import(
      /* webpackChunkName: "routes" */
      `../views/Listing.vue`
    ),
};
