export default {
  path: "/",
  meta: { auth: true, public: false },
  title: "Login",
  name: "Login",
  component: () =>
    import(
      /* webpackChunkName: "routes" */
      `../views/Login.vue`
    )
};
