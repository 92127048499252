export default {
  path: "/db",
  meta: { auth: true, public: false },
  title: "DB",
  name: "DB",
  component: () =>
    import(
      /* webpackChunkName: "routes" */
      `../views/Db.vue`
    )
};
