var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticClass:"be-drawer-menu",attrs:{"disable-resize-watcher":"","app":"","mini-variant":_vm.closeDrawer && _vm.$vuetify.breakpoint.mdAndUp && _vm.hover ? false : _vm.closeDrawer,"permanent":"","stateless":"","width":_vm.$vuetify.breakpoint.mdAndUp ? undefined : '256px'},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{staticClass:"white--text",attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();_vm.closeDrawer = !_vm.closeDrawer}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"white--text",attrs:{"color":"white"}},[_vm._v("fa-bars")])],1),_c('v-list-item-content')],1)],1),_c('div',{on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('v-list',{attrs:{"dense":"","nav":""}},_vm._l((_vm.items),function(item){return _c('div',{key:item.title},[(item.sub_items)?_c('v-list-group',{key:item.title,attrs:{"to":item.name
              ? {
                name: item.name,
                params: item.params ? item.params : {},
              }
              : undefined},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',{staticClass:"white--text",class:item.icon.includes('fa') ? '' : 'ms-n1',attrs:{"color":"white","size":item.icon.includes('fa') ? 20 : 24}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',{staticClass:"white--text"},[_vm._v(_vm._s(item.title))])]},proxy:true}],null,true)},[_c('div',[_vm._l((item.sub_items),function(sub){return [_c('v-list-item',{key:sub.title,attrs:{"to":sub.name
                    ? {
                      name: sub.name,
                      params: sub.params ? sub.params : {},
                    }
                    : undefined,"link":""},on:{"click":() => {
    sub.resolve
      ? _vm.$router.resolve({ name: sub.resolve })
      : undefined;
  }}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"white--text",class:sub.icon.includes('fa') ? '' : 'ms-n1',attrs:{"color":"white","size":sub.icon.includes('fa') ? 18 : 24}},[_vm._v(_vm._s(sub.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"white--text"},[_vm._v(_vm._s(sub.title)+" ")])],1)],1)]})],2)]):_c('v-list-item',{key:item.title + 1,attrs:{"to":item.name
              ? {
                name: item.name,
                params: item.params ? item.params : {},
              }
              : undefined,"link":""},on:{"click":() => {
    item.resolve
      ? _vm.$router.resolve({ name: item.resolve })
      : undefined;
  }}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"white--text",class:item.icon.includes('fa') ? '' : 'ms-n1',attrs:{"color":"white","size":item.icon.includes('fa') ? 20 : 24}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',{staticClass:"me-1 px-2 ms-n5"},[_c('v-list-item-title',{staticClass:"white--text"},[_vm._v(_vm._s(item.title)+" ")])],1)],1)],1)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }