<template>
  <v-app id="App">
    <TopBar v-if="$route.name != 'Login'" />
    <Nav v-if="$route.name !== 'Login'"></Nav>
    <v-main v-if="token">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Nav from "../components/Nav/NavBar.vue";
import TopBar from '../components/TopBar/index.vue'

export default {
  name: "App",
  components: {
    Nav,
    TopBar
  },
  methods: {
    token() {
      if (localStorage.getItem('token') != '') {
        console.log('Tem token')
      }
      else {
        this.$router.push({ name: 'Login' })
      }
    },
    clearToken() {
      setTimeout(() => {
        localStorage.removeItem('token');
      }, 1000 * 60 * 60)
    }
  },
  created() {
    this.token()
    this.clearToken()
  }

};
</script>

<style scoped>
#App {
  background: transparent linear-gradient(180deg, #2c4e66 0%, #7497af 100%) 0% 0% no-repeat padding-box;
  font-family: 'Ubuntu', sans-serif;
}
</style>
