import Vue from "vue";
import Router from "vue-router";
import db from "./db";
import login from "./login";
import listing from "./listing";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [db, login, listing],
});
