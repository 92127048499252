<template>
  <v-app-bar app :class="`be-app-bar pb-2 ${scrollClass}`" elevation="0">
    <v-img class="mx-2" src="https://becompliance-resource.s3.us-east-2.amazonaws.com/app-resource/logobranco.png"
      max-height="60" max-width="100" contain></v-img>

    <v-spacer></v-spacer>

    <v-menu bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text class="white--text" v-bind="attrs" v-on="on">
          {{ getFirstName() }} <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>

        <v-list-item @click="logout">
          <v-btn color="transparent" text>
            <i class="fas fa-sign-out-alt mr-1 blue-grey--text text--lighten-1 "></i>
            <v-list-item-action-text>Sair</v-list-item-action-text>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {

  data() {
    return {
      scrollClass: ''
    }
  },

  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (window.top.scrollY < 60) {
        this.scrollClass = ''
      } else {
        this.scrollClass = 'on-scrool'
      }
    },

    getFirstName() {
      // const name = this.$store.state.user.name
      // return name.split(" ")[0]
      return 'Thiago'
    },

    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user_roles');
      localStorage.removeItem('role');
      window.location = '/'
    },
    // checkModel() {
    //   if (this.$store.state.user.user_modules.indexOf('compliance') !== -1) {
    //     return true
    //   }
    //   return false
    // },
  }
}
</script>

<style scoped lang="scss">
.be-app-bar {
  background-color: transparent !important;
}

.be-app-bar.on-scrool {
  background-color: rgba(52, 73, 94, 0.9) !important;
}
</style>
