<template>
  <v-navigation-drawer disable-resize-watcher app class="be-drawer-menu" :mini-variant="
    closeDrawer && $vuetify.breakpoint.mdAndUp && hover ? false : closeDrawer
  " permanent stateless v-model="drawer" :width="$vuetify.breakpoint.mdAndUp ? undefined : '256px'">
    <!-- Icone do menu -->
    <v-list dense nav>
      <v-list-item class="white--text" link @click.stop="closeDrawer = !closeDrawer">
        <v-list-item-icon>
          <v-icon color="white" class="white--text">fa-bars</v-icon>
        </v-list-item-icon>
        <v-list-item-content> </v-list-item-content>
      </v-list-item>
    </v-list>

    <div @mouseover="hover = true" @mouseleave="hover = false">
      <v-list dense nav>
        <div v-for="item in items" :key="item.title">
          <!-- Items com SubItems -->
          <v-list-group :key="item.title" :to="
            item.name
              ? {
                name: item.name,
                params: item.params ? item.params : {},
              }
              : undefined
          " v-if="item.sub_items">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon color="white" class="white--text" :class="item.icon.includes('fa') ? '' : 'ms-n1'"
                  :size="item.icon.includes('fa') ? 20 : 24">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title class="white--text">{{
                  item.title
              }}</v-list-item-title>
            </template>

            <div>
              <template v-for="sub in item.sub_items">
                <v-list-item :key="sub.title" :to="
                  sub.name
                    ? {
                      name: sub.name,
                      params: sub.params ? sub.params : {},
                    }
                    : undefined
                " link @click="
  () => {
    sub.resolve
      ? $router.resolve({ name: sub.resolve })
      : undefined;
  }
">
                  <v-list-item-icon>
                    <v-icon color="white" class="white--text" :class="sub.icon.includes('fa') ? '' : 'ms-n1'"
                      :size="sub.icon.includes('fa') ? 18 : 24">{{ sub.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="white--text">{{ sub.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </div>
          </v-list-group>
          <!-- Items sem SubItems -->
          <v-list-item :key="item.title + 1" :to="
            item.name
              ? {
                name: item.name,
                params: item.params ? item.params : {},
              }
              : undefined
          " link @click="
  () => {
    item.resolve
      ? $router.resolve({ name: item.resolve })
      : undefined;
  }
" v-else>
            <v-list-item-icon>
              <v-icon color="white" class="white--text" :class="item.icon.includes('fa') ? '' : 'ms-n1'"
                :size="item.icon.includes('fa') ? 20 : 24">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="me-1 px-2 ms-n5">
              <v-list-item-title class="white--text">{{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    route: null,
    store: null,
  },

  data() {
    return {
      hover: false,
      items: [
        {
          title: "Setup de Migração",
          icon: "fa-server",
          name: "DB",
        },
        {
          title: "Listagem dos Bancos Migrados",
          icon: "fa-scroll",
          name: "Listing",
        },
      ],
      drawer: true,
      openDrawer: false,
      closeDrawer: !this.$vuetify.breakpoint.mdAndUp,
    };
  },
};
</script>

<style lang="scss" scoped>
.be-drawer-menu ::v-deep .v-list-item__title {
  font-weight: bold;
}

.be-drawer-menu {
  background-color: #34495E !important;
}

.be-drawer-menu ::v-deep .v-icon {
  color: #fff;
}

.be-drawer-menu ::v-deep .v-list-group__items {
  margin-left: 10px;
  border-left: 1px dotted #fff;
}

.white--text {
  color: #fff;
}
</style>
